
import getCookie from './Cookie';
import packageJson from '../../package.json';


class APIError extends Error {
  status: number;
  errorData: any;
  constructor(message?: string, statusCode?: number, errorData?: any) {
    super(message)
    if (statusCode) {
      this.status = statusCode;
    }
    else {
      this.status = 0;
    }

    if (errorData) {
      this.errorData = errorData;
    }
    else {
      this.errorData = {}
    }
  }
}

class BaseAPI {
  apiEndpoint = (process.env.NODE_ENV === 'production') ? packageJson.apiEndpoint : packageJson.apiEndpointDebug;
  path = '';

  get csrfToken() {
    return getCookie('csrftoken');
  }

  get baseUrl() {
    return `${this.apiEndpoint}${this.path}`;
  }

  async doFetch(params?: any): Promise<object[]> {
    let url = new URL(this.baseUrl);

    if (params) {
      (url.search as any) = new URLSearchParams(params);
    }

    // console.log('fetch', params, url.toString());

    let raw;
    let response = await fetch(url.toString(), {
      credentials: 'include'  
    });

    if ((response.status >= 300) && (response.status < 600)) {
      throw new APIError(response.statusText, response.status, null);
    }

    raw = await response.json();
    this.isFetching = false;

    // console.log(raw);
    return raw;
  }

  isFetching: boolean = false;
  fetchPromise?: Promise<object[]>;

  fetch(params?: any): Promise<object[]> {
    return this.doFetch(params);
  }


  pendingGet: any = {}

  async get(path: string=''): Promise<object> {
    // if multiple get() run at the same time
    // no need to open multiple connections to the server
    if (this.pendingGet[path]) {
      let p = this.pendingGet[path];
      let value = await p;
      return value;
    }
    else {
      this.pendingGet[path] = this.rawGet(path);
      let value = await this.pendingGet[path];
      delete this.pendingGet[path];
      return value;
    }
  }

  async rawGet(path: string): Promise<object> {
    let raw;
    let url = `${this.baseUrl}${path}`;

    try {
      let fetchPromise = fetch(url, {
        credentials: 'include'  
      });

      let response = await fetchPromise;      

      if ((response.status >= 300) && (response.status < 600)) {
        throw new APIError(response.statusText, response.status, null);
      }

      raw = await response.json();
    }
    catch (error) {
      throw new APIError(error.message, (error as any).status ? (error as any).status : 1200, null);
    }

    return raw;
  }

  async post(data: any, path="") {
    let raw: any;
    const asFormData = data instanceof FormData;

    let url = `${this.baseUrl}${path}`;
    let method = 'POST';

    let headers: any = {
      "X-CSRFToken": this.csrfToken,
    };

    if (!asFormData) {
      headers['Content-Type'] = 'application/json';
    }

    let response = undefined;

    try {
      response = await fetch(url, {
        credentials: 'include',
        method: method,
        body: asFormData ? data : JSON.stringify(data),
        headers: headers
      });

      if ((response.status >= 300) && (response.status < 600)) {
        throw new APIError(response.statusText, response.status, null);
      }
    }
    catch (error) {
      throw new APIError(error.message, 1200, null);
    }

    try {
      raw = await response.json();
    }
    catch (jsonParsingError) {
      console.log(jsonParsingError)
      raw = {success: false, errors: {non_field_errors: [`${jsonParsingError}`]}}
    }

    return [raw, response];
  }

}

export {
  BaseAPI,
  APIError,
}