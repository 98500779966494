import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import UnsupportedBrowserPage from './pages/UnsupportedBrowserPage';
import initPolyfill from './utils/polyfill';

import * as serviceWorker from './serviceWorker';

import * as Sentry from '@sentry/browser';

initPolyfill();

const appConfig = (window as any).APP_CONFIG;

if (appConfig.sentryDSN && (appConfig.sentryDSN !== "__SENTRY_DSN__")) {
  Sentry.init({
    dsn: appConfig.sentryDSN,
    beforeSend: (event, hint) => {
      // console.log('sentry before send', event)
      if (event.exception) {
        const user: any = {}
        if ((window as any)._cache?.email) {
          user.email = (window as any)._cache.email;
        }
        // Sentry.showReportDialog({ eventId: event.event_id, user: user });
      }
      return event;
    }
  });
}

function isSupportedBrowser() {
  let supported = true;
  if (window.navigator.userAgent.indexOf("Trident") > 0) supported = false;
  if (window.navigator.userAgent.indexOf("MSIE ") > 0) supported = false;

  return supported;
}

if (!isSupportedBrowser()) {
  ReactDOM.render(
    <React.StrictMode>
      <UnsupportedBrowserPage />
    </React.StrictMode>,
    document.getElementById('root')
  );
}
else {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root')
  );
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
