import React, {
  useState,
  useEffect
} from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link as RouterLink,
} from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Breadcrumbs,
  Link,
  Typography,
  Button,
} from '@material-ui/core';

import logo512 from '../img/logo512.png';

const useStyles = makeStyles((theme) => ({
  root: {

  },
  pageContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 400,
    margin: 20,
  },
  mainLogo: {
    display: 'block',
    marginTop: 20,
    marginBottom: 20
  },
  pageTitle: {
    marginBottom: 20
  },
  browserSelectionList: {
    marginTop: 20,
  }
}));
  

function UnsupportedBrowserPage() {
  const classes = useStyles();
  return (
    <>
      <Paper className={classes.pageContainer}>
        <img src={logo512} alt="" width="100" height="100" className={classes.mainLogo} />
        <Typography variant="h5" className={classes.pageTitle}>Unsupported Browser</Typography>
        <Typography>Your browser is unsupported. Please use a modern browser to access our customizer app.</Typography>
        <div className={classes.browserSelectionList}>
          <Button href="https://www.google.com/chrome/" target="_blank">Chrome</Button>
          <Button href="https://www.mozilla.org/en-US/firefox/" target="_blank">Firefox</Button>
          <Button href="https://www.opera.com/download" target="_blank">Opera</Button>
          <Button href="https://brave.com/download/" target="_blank">Brave</Button>
          <Button href="https://support.apple.com/downloads/safari" target="_blank">Safari</Button>
          <Button href="https://www.microsoft.com/en-us/edge" target="_blank">Edge</Button>
        </div>
      </Paper>
    </>
  );
}

export default UnsupportedBrowserPage;